exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-albums-tsx": () => import("./../../../src/pages/albums.tsx" /* webpackChunkName: "component---src-pages-albums-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-album-template-tsx": () => import("./../../../src/templates/album-template.tsx" /* webpackChunkName: "component---src-templates-album-template-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog-index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

